const init = () => {
  const btn = document.querySelector(".button.button--menu");
  const menu = document.querySelector(".title-bar-content-menu-mobile");
  const links = menu.querySelectorAll(".menu-item a");
  btn.addEventListener("click", () => {
    console.log(123);
    menu.classList.toggle("open");
    btn.classList.toggle("open");
  });

  links.forEach((link) => {
    link.addEventListener("click", () => {
      menu.classList.remove("open");
      btn.classList.remove("open");
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  console.log("Top-bar enabled!");
  init();
});

// window.onload = function () {
//   console.log("Top-bar enabled!");
//   init();
// };
